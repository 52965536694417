<template>
	<CustomModal custom-header custom-footer class="progress-bar-modal">
		<template #header>
			<div class="modal-header">
				<h6 class="modal-title">{{ $t("ImportFile.Finish") }}</h6>
				<CButtonClose @click="hideModal" />
			</div>
		</template>

		<template #content>
			<div class="modal-container">
				<div class="import-success">
					{{
						$t("ImportFile.NumberSuccess", {
							rate: rateSuccess,
						})
					}}
				</div>
				<div class="import-errors">
					<div class="text-danger mb-2">
						{{
							$t("ImportFile.NumberErrors", {
								number: totalImport - numberSuccess,
							})
						}}:
					</div>
					<div v-for="error in status.errors" :key="error" class="mb-1">
						{{ error }}
					</div>
				</div>
			</div>
		</template>

		<template #footer>
			<div class="modal-footer">
				<CButton
					v-if="isDuplicateCustomer"
					v-bind="{ variant: 'outline' }"
					color="warning"
					@click="onMergeConflict"
					>{{ $t("Button.Merge") }}</CButton
				>

				<CButton color="outline-info" @click="hideModal">
					{{ $t("Button.OK") }}
				</CButton>
			</div>
		</template>
	</CustomModal>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
const { mapState } = createNamespacedHelpers("serviceAppointment")

export default {
	name: "ImportSuccessModal",

	data() {
		return {
			status: {},
		}
	},

	computed: {
		...mapState(["importStatus"]),

		numberSuccess: function () {
			return Number(this.status?.success | "0")
		},

		totalImport: function () {
			return Number(this.status?.total | "0")
		},

		rateSuccess: function () {
			return `${this.numberSuccess}/${this.totalImport}`
		},

		isDuplicateCustomer: function () {
			return !!Object.keys(this.importStatus.duplicateCustomer).length
		},
	},

	watch: {
		importStatus: function () {
			this.setUpData()
		},
	},

	created() {
		this.setUpData()
	},

	methods: {
		setUpData() {
			this.status = { ...this.importStatus }
		},

		onMergeConflict() {
			this.$emit("merge-conflict", this.importStatus)
		},

		hideModal() {
			this.$emit("finish-import", this.importStatus)
		},
	},
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.progress-bar-modal {
	/deep/ .modal-footer {
		justify-content: center;
	}
	.import-success {
		color: $color-sea-green;
		margin-bottom: 1rem;
	}
	.import-errors {
		max-height: 360px;
		overflow-y: auto;

		@media (min-width: $xs) {
			max-height: 500px;
		}
	}
}
</style>
